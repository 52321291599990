import { Component } from 'react';
import { routes } from '../Routes/Dashboard';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Props, Routes, States } from '../Redux/Models';
import { Footer, Header } from '../Components';
import { AuthService } from '../Services';

class DashboardLayout extends Component<Props, States> {
    public state: any = { menuOpen: false, fixed: false, open: false, header: true, footer: true };
    public componentDidMount() {
        this.props.dispatch(AuthService.getMe());
    }

    public noHeaderFooter = () => {
        this.setState((preState: any) => {
            preState.header = false;
            preState.footer = false;
            return preState;
        });
    };

    public showHeaderFooter = () => {
        this.setState((preState: any) => {
            preState.header = true;
            preState.footer = true;
            return preState;
        });
    };

    public render() {
        const params = {
            showHeaderFooter: this.showHeaderFooter,
            noHeaderFooter: this.noHeaderFooter
        };
        return (
            <div>
                {this.state.header && <Header />}
                <Switch>
                    {routes.map((route: Routes, idx) => {
                        const title = { title: route.name };
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                render={(props: Props) => (
                                    <route.component {...this.props} {...props} {...title} {...params} />
                                )}
                            />
                        ) : null;
                    })}

                    <Redirect to="/" from="/" />
                </Switch>
                {
                    /*                     this.state.footer &&
                        (window.location.pathname.length === 1 ? (
                            <Footer dashboard='dashboard' />
                        ) : !window.location.pathname.includes('documents') ? (
                            <Footer dashboard='' />
                        ) : (
                            ''
                        )) */
                    this.state.footer &&
                    (!window.location.pathname.includes('/documents') && window.location.pathname.length > 1) ? (
                        <Footer />
                    ) : (
                        ''
                    )
                    // this.state.footer && <Footer />
                }
            </div>
        );
    }
}

export default DashboardLayout;
