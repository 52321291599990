import { createTheme } from '@material-ui/core/styles';

export const PrimaryTheme = createTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#f26b41'
    }
  },

  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: '20px',
        // backgroundColor: '#e8e8f3'
      }
    },
    MuiDialogTitle: {
      root: {
        color: '#f26b41',
      }
    },
    MuiDialogActions: {
      root: {
        padding: '16px 24px'
      }
    },
    MuiButton: {
      root: {
        padding: '20px',
        textTransform: 'none',
        minWidth: '116px',
        maxHeight: '58px'
      }

    }
  },
  zIndex: {
    tooltip: 9999,
  },
  props: {
    MuiTextField: {
      variant: 'outlined',
      color: 'secondary',

    },
    MuiButton: {
      variant: 'contained',
      color: 'primary',
    }
  }
});