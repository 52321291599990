import loadable from "@loadable/component";
import { Fallback } from '../Components';
import { Routes } from "../Redux/Models";

const DashboardView = loadable(() => import("../Views/Dashboard/DashboardView"), { fallback: <Fallback /> });
// const DocumentListView = loadable(() => import("../Views/Dashboard/DocumentListView"), { fallback: <Fallback /> });
const ProfileInfo = loadable(() => import("../Views/Dashboard/MyProfile/ProfileInfo"), { fallback: <Fallback /> });
const ChangePassword = loadable(() => import("../Views/Dashboard/MyProfile/ChangePassword"), { fallback: <Fallback /> });

export const routes: Array<Routes> = [
    { path: '/', exact: true, name: 'Dashboard', component: DashboardView },
    { path: '/profile', exact: true, name: 'ProfileInfo', component: ProfileInfo },
    { path: '/change-password', exact: true, name: 'ChangePassword', component: ChangePassword },
];
