import styled from 'styled-components';
import { Colors } from '../../Configs/Constants';

export const HeaderPart = styled.header`
    position: fixed;
    top: 0;
    height: 100px;
    width: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;
    z-index: 1299;
    transition-duration: 0.5s;
    &.fixed {
        background-color: white;
        box-shadow: ${Colors.globalBoxShadow};
        height: 80px;
    }

    .img-fluid {
        width: 200px;
        margin-right: 70px;
    }
    nav {
        ul {
            list-style-type: none;
            padding: 0;
            display: flex;
            align-items: center;
            margin: 0;
            li {
                padding: 20px;
                a {
                    color: ${Colors.black};
                    text-decoration: none;
                }
            }
        }
    }
    .button-holder {
        margin-left: auto;
    }

    .menu-holder {
        position: fixed;
        top: 100px;
        right: 0;
        width: 35px;
        height: 35px;
        padding: 5px !important;
        border-radius: 100% 0 0 100%;
        z-index: 9999;
    }

    @media (min-width: ${Colors.bu4}) {
        .only-mobile {
            display: none;
        }
    }
    .go-to-profile {
        display: none;
    }
    @media (max-width: ${Colors.b4}) {
        height: 72px;
        .img-fluid {
            width: 130px;
            margin-right: 20px;
        }
        nav {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 9999;
            &.menu-close {
                display: none;
            }
            ul {
                flex-direction: column;
                align-items: flex-start;
                background-color: white;
                box-shadow: ${Colors.globalBoxShadow};
                height: 100%;
                display: inline-block;
                width: 70%;
                max-width: 350px;
                overflow: auto;
                li {
                    padding: 15px;
                }
            }
        }
        .desktop-login-buynow-btn {
            display: none !important;
        }
        .go-to-profile {
            display: block;
            .display-picture-section {
                .initials {
                    width: 0;
                    height: 0;
                    margin: 0 auto;
                    background: lightgray;
                    position: relative;
                    border-radius: 50%;
                    padding: 30px;
                    font-size: 30px;
                    font-weight: 900;
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    .text {
                        color: gray;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
            .name {
                text-align: center;
                text-transform: capitalize;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                padding: 15px 15px 0;
                white-space: nowrap;
                .signature {
                    height: 25px;
                }
            }
            .btn-container {
                padding: 15px;
                .btn {
                    width: 100%;
                }
            }
        }
    }
    .avatar {
        position: relative;
        #profDrop {
            width: 0;
            height: 0;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -99999;
        }
        .profileList {
            display: none;
            position: absolute;
            top: 110%;
            right: 0;
            background: #fff;
            border: 2px solid lightgray;
            padding: 0;
            list-style-type: none;
            width: 150px;
            border-radius: 10px;
            // max-width: 200px;
            hr {
                margin: 0 auto;
                width: 80%;
                height: 2px;
                border: none;
                background: lightgray;
            }
            a {
                text-decoration: none !important;
            }
            li {
                padding: 10px 20px;
                font-size: 16px;
                cursor: pointer;
                color: black;
                &:hover {
                    background: #f5f5f5;
                }
            }
        }
        #profDrop:checked ~ .profileList {
            display: block;
        }
        label {
            display: block;
            height: 0;
            width: 0;
            padding: 25px;
            margin: 0;
            border-radius: 50%;
            cursor: pointer;
            position: relative;
        }
        .profile-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-radius: 50%;
        }
        .initials {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background: #000;
            border-radius: 50%;
            .text {
                font-size: 20px;
                position: absolute;
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
            }
        }
    }
    @media (min-width: ${Colors.bu2k}) {
        height: 200px;
        &.fixed {
            background-color: white;
            box-shadow: ${Colors.globalBoxShadow};
            height: 160px;
        }

        .img-fluid {
            width: 300px;
            margin-right: 140px;
        }
    }

    @media (min-width: ${Colors.bu4k}) {
        height: 300px;
        &.fixed {
            background-color: white;
            box-shadow: ${Colors.globalBoxShadow};
            height: 240px;
        }

        .img-fluid {
            width: 450px;
            margin-right: 210px;
        }
    }
    .logout-btn {
        display: none;
    }
    @media (max-width: 769px) {
        .img-fluid {
            width: 90px;
            margin-left: 20px;
        }
        .logout-btn {
            min-width: 0 !important;
            // border: none;
            padding: 10px;
        }
    }
`;

export const WrapperDialogContent = styled.div`
    max-width: 600px;
    margin: auto;
`;

export const DialogLogo = styled.img`
    width: 150px;
`;
export const Arrow = styled.img`
    width: 15px;
`;

export const Form = styled.form`
    margin-top: 70px;
`;
