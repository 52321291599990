import loadable from "@loadable/component";
import { Fallback } from '../Components';
import { Routes } from "../Redux/Models";

const HomeView = loadable(() => import("../Views/Home/HomeView/HomeView"), { fallback: <Fallback /> });
const LoginView = loadable(() => import("../Views/Home/LoginView/LoginView"), { fallback: <Fallback /> });
const RegisterView = loadable(() => import("../Views/Home/RegisterView/RegisterView"), { fallback: <Fallback /> });
const SetPassword = loadable(() => import("../Views/Home/SetPasswordView/SetPasswordView"), { fallback: <Fallback /> });
const ForgotPassword = loadable(() => import("../Views/Home/ForgotPasswordView/ForgotPasswordView"), { fallback: <Fallback /> });
const ResetPassword = loadable(() => import("../Views/Home/ResetPassword/ResetPassword"), { fallback: <Fallback /> });
const RecipientSignerView = loadable(() => import("../Views/Home/RecipientSignerView"), { fallback: <Fallback /> });
const FinalPreviewView = loadable(() => import("../Views/Home/FinalPreview"), { fallback: <Fallback /> });


export const routes: Array<Routes> = [
    { path: '/', exact: true, name: 'Home', component: HomeView },
    { path: '/verify-email/:token', exact: true, name: 'Verify Email', component: HomeView },
    { path: '/login', exact: true, name: 'Login', component: LoginView },
    { path: '/register', exact: true, name: 'Register', component: RegisterView },
    { path: '/set-password/:token', exact: true, name: 'Set Password', component: SetPassword },
    { path: '/forgot-password', exact: true, name: 'Forgot Password', component: ForgotPassword },
    { path: '/reset-password/:token', exact: true, name: 'Reset Password', component: ResetPassword },
    { path: '/preview/:token', exact: true, name: 'Document Preview', component: FinalPreviewView },
    { path: '/recipients/:token', exact: true, name: 'Review And Sign Document', component: RecipientSignerView },
];