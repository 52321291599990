import React, { Component } from 'react';
import { routes } from '../Routes/Auth';
import { Switch, Route, Redirect } from "react-router-dom";
import { Props, Routes } from '../Redux/Models';
import { Footer, Header } from '../Components';


class HomeLayout extends Component {
    public state: any = { header: true, footer: true };

    public noHeaderFooter = () => {
        this.setState((preState: any) => {
            preState.header = false;
            preState.footer = false;
            return preState;
        });
    };

    public showHeaderFooter = () => {
        this.setState((preState: any) => {
            preState.header = true;
            preState.footer = true;
            return preState;
        })
    };

    public render() {

        const params = {
            showHeaderFooter: this.showHeaderFooter,
            noHeaderFooter: this.noHeaderFooter
        }
        return (
            <div>
                {this.state.header && <Header />}
                <Switch>
                    {
                        routes.map((route: Routes, idx) => {
                            const title = { title: route.name }
                            return route.component ? (
                                <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    render={(props: Props) => <route.component {...this.props} {...props} {...title} {...params} />}
                                />
                            ) : null;
                        })
                    }
                    <Redirect to="/" from="/" />
                </Switch>
                {this.state.footer && <Footer />}
            </div>
        )
    }
}

export default HomeLayout;
