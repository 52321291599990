import BaseService from './BaseService';
import { Http } from '../Http';

import { Login, Logout, LoadUser } from '../Redux/Actions';
import { Response, /* ResponseList, */ UserModel /* Options */ } from '../Redux/Models';

export default class AuthService extends BaseService {
    public static login(params: Object) {
        return (dispatch: Function) =>
            new Promise((resolve, reject) => {
                Http.post('/auth/login', params)
                    .then((res) => {
                        if (res?.data.status === 200) {
                            dispatch(Login(res?.data.data));
                            localStorage.setItem('token', res?.data.data.token);
                        }
                        return resolve(res?.data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
    }

    public static verifyEmail(token: string): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.get(`/auth/verify-email/${token}`)
                .then((res) => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static updateProfile(params: Object) {
        return (dispatch: Function) =>
            new Promise((resolve, reject) => {
                Http.put('/auth/update-profile', params)
                    .then((res) => {
                        if (res?.data.status === 200) {
                            dispatch(LoadUser(res?.data.data));
                        }
                        let data = new Response<UserModel>(res?.data);
                        return resolve(data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
    }

    public static updateContact(params: Object) {
        return (dispatch: Function) =>
            new Promise((resolve, reject) => {
                Http.put('/auth/update-contact', params)
                    .then((res) => {
                        if (res?.data.status === 200) {
                            dispatch(LoadUser(res?.data.data));
                        }
                        let data = new Response<UserModel>(res?.data);
                        return resolve(data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
    }

    public static uploadImage(params: FormData) {
        return (dispatch: Function) =>
            new Promise((resolve, reject) => {
                Http.post('/auth/profile-image', params)
                    .then((res) => {
                        if (res?.data.status === 201) {
                            dispatch(LoadUser(res?.data.data));
                        }
                        let data = new Response<null>(res?.data);
                        return resolve(data);
                    })
                    .catch((err) => {
                        console.error(err);
                        return reject(err);
                    });
            });
    }

    public static setPassword(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/set-password', params)
                .then((res) => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static requestPassword(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/forgot-password', params)
                .then((res) => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static verificationCode(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/compare-verification-code', params)
                .then((res) => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static register(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/register', params)
                .then((res) => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static resendVerificationMail(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/verification-mail', params)
                .then((res) => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static getMe() {
        return (dispatch: Function) =>
            new Promise((resolve, reject) => {
                Http.get('/auth/me')
                    .then((res) => {
                        if (res?.data.status === 200) {
                            dispatch(LoadUser(res?.data.data));
                        }
                        return resolve(res?.data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
    }

    public static dashboard() {
        return new Promise((resolve, reject) => {
            Http.get('/auth/dashboard')
                .then((res) => {
                    return resolve(res?.data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }

    public static logout() {
        return (dispatch: Function) =>
            new Promise((resolve, reject) => {
                Http.post('/auth/logout')
                    .then((res) => {
                        if (res?.data.status === 200) {
                            dispatch(Logout());
                            //localStorage.clear();
                        }
                        return resolve(res?.data);
                    })
                    .catch((err) => {
                        return reject(err);
                    });
            });
    }

    public static updatePassword(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.put('/auth/update-password', params)
                .then((res) => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch((err) => {
                    console.error(err);
                    return reject(err);
                });
        });
    }

    public static resetPassword(params: Object): Promise<Response<null>> {
        return new Promise((resolve, reject) => {
            Http.post('/auth/reset-password', params)
                .then((res) => {
                    let data = new Response<null>(res?.data);
                    return resolve(data);
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    }
}
