import { Component } from 'react';
import { routes } from '../Routes/Admin';
import { Switch, Route, Redirect } from "react-router-dom";
import { Props, Routes, States } from '../Redux/Models';
import { Footer, Header } from '../Components';
import { AuthService } from '../Services';


class AdminLayout extends Component<Props, States> {
    public state: any = { menuOpen: false, fixed: false, open: false, header: true, footer: true };
    public componentDidMount() {
        this.props.dispatch(AuthService.getMe());
    };


    // public noHeaderFooter = () => {
    //     this.setState((preState: any) => {
    //         preState.header = false;
    //         preState.footer = false;
    //         return preState;
    //     });
    // };

    // public showHeaderFooter = () => {
    //     this.setState((preState: any) => {
    //         preState.header = true;
    //         preState.footer = true;
    //         return preState;
    //     })
    // };

    public render() {
        // const params = {
        //     showHeaderFooter: this.showHeaderFooter,
        //     noHeaderFooter: this.noHeaderFooter
        // }
        return (
            <div>
                <Header />
                <Switch>
                    {routes.map((route: Routes, idx) => {
                        const title = { title: route.name }
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                render={(props: Props) => <route.component {...this.props} {...props} {...title} />}
                            />
                        ) : null;
                    })}

                    <Redirect to="/" from="/" />
                </Switch>
                <Footer />
            </div>
        )
    }
}

export default AdminLayout;
