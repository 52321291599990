import { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { Logo } from '../../Helpers/Images';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { windowScrollEvent } from '../../Services/global-service';
import { connect } from 'react-redux';
import { Logout } from '../../Redux/Actions';
import { Props, States } from '../../Redux/Models';
import { HeaderPart } from './styled';
import ProfileLeft from '../Profiles/ProfileLeft';
import HeaderDropDown from '../Profiles/HeaderDropDown';
import { Util } from '../../Helpers';
import { toggleDocLeft, toggleProfLeft } from '../../Redux/Actions/action';
import { Box, List, ListItem, Button, Typography } from '@material-ui/core';

export class Header extends Component<Props, States> {
    private unsubscribe: Subject<{}>;
    public state: States;
    constructor(props: Props) {
        super(props);
        this.state = {
            menuOpen: false,
            fixed: false,
            signature: this.props.user?.signature?.path,
            profileImg: this.props.user?.profileImg
        };
        this.unsubscribe = new Subject();
    }

    public componentDidMount() {
        windowScrollEvent.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            data > 80 ? this.setState({ fixed: true }) : this.setState({ fixed: false });
        });
    }

    public componentWillUnmount() {
        this.unsubscribe.next();
        this.unsubscribe.unsubscribe();
    }

    public menuOpen = () => {
        this.setState({ menuOpen: true });
    };

    public closeMenu = ($e: any) => {
        /*if ($e.target.id === "nav")*/ this.setState({ menuOpen: false });
    };

    public openProfLeftBar = () => {
        this.props.toggleProfLeft(true);
    };

    public closeProfLeftBar = ($e: any) => {
        this.props.toggleProfLeft(false);
    };

    public openDocListLeftBar = () => {
        this.props.toggleDocLeft(true);
    };

    public closeDocListLeftBar = ($e: any) => {
        this.props.toggleDocLeft(false);
    };

    public logout = () => {
        this.props.dispatch(Logout());
    };

    public render() {
        // console.log(this.props.user.firstName);

        const { isAuthenticated } = this.props;
        // console.log(this.props.history)
        return (
            // <HeaderPart className={`container ${this.state.fixed ? 'fixed' : ''}`}>
            <HeaderPart className="container fixed d-flex justify-content-between">
                {/* <button className="btn btn-primary menu-holder only-mobile" onClick={this.menuOpen}>
                    <i className="fas fa-arrow-left"></i>
                </button> */}
                {(this.props.history.location.pathname.includes('/profile')) ? (
                    this.props.isProfileLeftBarOpen ? (                          
                        <CloseIcon
                            className="only-mobile"
                            style={{ cursor: 'pointer' }}
                            onClick={this.closeProfLeftBar}
                        />
                    ) : (
                        <MenuIcon className="only-mobile" style={{ cursor: 'pointer' }} onClick={this.openProfLeftBar} />
                    )
                ) : (this.props.history.location.pathname.includes('/documents')) ? (
                        this.props.isDocListLeftBarOpen ? (                        
                            <CloseIcon
                                className="only-mobile"
                                style={{ cursor: 'pointer' }}
                                onClick={this.closeDocListLeftBar}
                            />
                        ) : (
                            <MenuIcon className="only-mobile" style={{ cursor: 'pointer' }} onClick={this.openDocListLeftBar} />
                        )
                ) : ('')}
                <Link to="/">
                    <img className="img-fluid" src="/assets/logo2.svg" alt="" />{' '}
                </Link>
                {isAuthenticated && (
                    <Button
                        className="btn btn-outline-primary btn-sm only-mobile ml-auto logout-btn"
                        onClick={this.logout}>
                        Logout
                    </Button>
                )}

                <nav
                    /*id="nav"*/
                    className={this.state.menuOpen ? '' : 'menu-close'}
                    onClick={this.closeMenu}>
                    <List className="nav-list">
                        <ListItem disableGutters className="only-mobile">
                            <Link to="/">
                                <img className="img-fluid" src={Logo} alt="" />{' '}
                            </Link>
                        </ListItem>
                        <Box className="go-to-profile">
                            <Box className="display-picture-section">
                                <span
                                    className="initials d-block"
                                    style={{
                                        backgroundImage: `url(${Util.getImageByPath(this.props?.user?.profileImg)})`
                                    }}>
                                </span>
                            </Box>
                            <Typography paragraph className="name">
                                {this.props?.user?.firstName} {this.props?.user?.lastName}
                                <br />
                                {this.state.signature ? (
                                    <img className="signature" src={`${Util.getImageByPath(this.state.signature)}`} alt="signature" />
                                ) : null}
                            </Typography>
                            <Box className="btn-container">
                                <Link to="/profile">
                                    <Button type="button" className="btn btn-outline-primary btn-sm">
                                        Go To Profile
                                    </Button>
                                </Link>
                            </Box>
                        </Box>
                        {/* <li>
                            <Link to="/">Product </Link>
                        </li>
                        <li>
                            <Link to="/">Uses Cases</Link>
                        </li>
                        <li>
                            <Link to="/add-documents">API</Link>
                        </li>
                        <li>
                            <Link to="/add-recipients">Pricing</Link>
                        </li> */}
                        {/* <li className="only-mobile">
                            <button className="btn btn-primary btn-sm btn-block">Buy Now</button>
                        </li> */}
                    </List>
                </nav>

                {!isAuthenticated ? (
                    <Box className="button-holder d-flex align-items-center">
                        <Link className="btn btn-outline-primary btn-sm" to="/login">
                            Login{' '}
                        </Link>
                        {/* <Link className="btn btn-primary btn-sm ml-3" to="/print">
                            Buy Now{' '}
                        </Link> */}
                    </Box>
                ) : (<HeaderDropDown />)}
            </HeaderPart>
        );
    }
}

const mapStateToProps = (state: States) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth.user,
        isProfileLeftBarOpen: state.LeftBars.isProfileLeftBarOpen,
        isDocListLeftBarOpen: state.LeftBars.isDocListLeftBarOpen
    };
};

const mapDispatchToProps = (dispatch: any) => {
	return {
		toggleDocLeft: (type: any) => dispatch(toggleDocLeft(type)),
        toggleProfLeft: (type: any) => dispatch(toggleProfLeft(type))
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
