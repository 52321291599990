import React, { Component } from 'react';
import logo from '../assets/images/logo.png';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const FallbackDiv = styled.div`
    position: fixed;
    top:0;
    left0;
    width:100%;
    height:100%;
    z-index:99999;
`;

export class Fallback extends Component {
    render() {
        return (
            <FallbackDiv className="flex-center text-center flex-column">
                <img src={logo} className="img-fluid" alt="" />
                <Typography paragraph className="mt-4">Loading....</Typography>
            </FallbackDiv>
        )
    }
}

export default Fallback
