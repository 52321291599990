import { Component } from 'react';
import { Variables } from '../Configs/Variables';
import styled from 'styled-components';
import { Box, Typography } from '@material-ui/core';

const FooterPart = styled.footer`
    background-color: ${Variables.black};
    min-height: 80px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    &.dashboard {
        display: none;
    }
    /*     position: fixed;
    left: 0;
    bottom: 0; */
    .img-fluid {
        filter: invert(100%);
        width: 150px;
        margin-right: 70px;
    }

    nav {
        ul {
            list-style-type: none;
            padding: 0;
            display: flex;
            align-items: center;
            margin: 0;
            li {
                padding: 20px;
                a {
                    color: white;
                    text-decoration: none;
                }
            }
        }
    }

    @media (max-width: ${Variables.b5}) {
        flex-direction: column;
        text-align: center;

        .img-fluid {
            margin: auto;
            margin-top: 10px;
        }

        ul {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    /*     @media only screen and (max-width: 800px) {
        .img-fluid {
            margin 10px 0 0;
        }
        nav ul {
            margin 0;
        }
        nav ul li {
            padding: 10px 20px;
        }
    }
    @media only screen and (max-width: 767px) { 
        height: 145px;
    } */
`;

export class Footer extends Component {
    render() {
        return (
            <>
                <FooterPart className={`container ${this.props.dashboard}`}>
                    <img className="img-fluid" src="/assets/logo1.svg" alt="" />
                    {/* <nav>
                        <ul>
                            <li> <Link to="/home">Product </Link> </li>
                            <li> <Link to="/login">Uses Cases</Link> </li>
                            <li> <Link to="/home">API</Link> </li>
                            <li> <Link to="/login">Pricing</Link> </li>
                        </ul>
                    </nav> */}
                    <Box
                        className="text-part"
                        style={{ backgroundColor: '#040404', textAlign: 'center', color: '#fff', fontSize: '13px' }}>
                        <Typography paragraph style={{ padding: '12px 0 4px', margin: "0", fontSize: '13px' }}>
                            {' '}
                            {'©'} Copyright <span style={{ fontWeight: 'bold' }}>SignMate </span> . All Rights Reserved{' '}
                        </Typography>
                        <a style={{ color: '#fff' }} href="mailto:hello@signmate.io">
                            hello@signmate.io
                        </a>
                    </Box>
                    <Box></Box>
                </FooterPart>
                {/* <Divider /> */}
            </>
        );
    }
}

Footer.defaultProps = {
    dashboard: ''
};

export default Footer;
