import axios, { CancelTokenSource as TokenSource, CancelTokenStatic as TokenStatic } from 'axios';
import Configs from './Configs';
import { ToastService } from './Helpers/Alert';
import Store from './Redux';
import { Logout } from './Redux/Actions';

axios.defaults.baseURL = Configs.API_URL;

axios.interceptors.response.use(
    resp => {
        if ((resp.data.status === 200 || resp.data.status === 201) && resp.data.message) {
            ToastService.success(resp.data.message);
        }
        return resp;
    },
    error => {
        if (error && error.response) {
            const { data } = error.response;
            if (data.status === 500) {
                ToastService.error(data.message);
            } else if ((data.status === 400) && data.message) {
                ToastService.warning(data.message);
            } else if ((data.status === 401) && data.message) {
                ToastService.warning(data.message);
                Store.dispatch(Logout());
            } else if ((data.status === 403) && data.message) {
                ToastService.warning(data.message);
            } else if ((data.status === 404) && data.message) {
                ToastService.warning(data.message);
            } else if ((data.status === 423) && data.message) {
                ToastService.warning(data.message);
            }

            return Promise.reject(data);
        }
    }
);
export const Http = axios;
export const FixedHttp = axios.create();

export interface CancelTokenSource extends TokenSource {

}
export interface CancelTokenStatic extends TokenStatic {

}
