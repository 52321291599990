import {combineReducers} from 'redux';
// import Alert from './Alert';
import Auth from './Auth.Reducer';
import Config from './Config.Reducer';
import LeftBars from './LeftBar.Reducers';

export default combineReducers({
    LeftBars,
    Auth,
    Config
});
