export const ADD_NOTE = "ADD_NOTE";
export const REMOVE_NOTE = "REMOVE_NOTE";
export const SHOW_ALL = "SHOW_ALL";
export const FETCH_DATA = "FETCH_DATA";
export const NO_FOOTER = "NO_FOOTER";
export const FOOTER = "FOOTER";
export const NO_HEADER_FOOTER = "NO_HEADER_FOOTER";
export const HEADER_FOOTER = "HEADER_FOOTER";
export const PROFILE_LEFT = "PROFILE_LEFT";
export const DOCUMENTS_LEFT = "DOCUMENTS_LEFT";

export function fetchData() {
  return async function (dispatch) {
    let promise = new Promise((resolve, reject) => {
      setTimeout(() => resolve("done!"), 3000);
    });

    let result = await promise;
    dispatch({ type: FETCH_DATA, data: result });
  };
}

export function showFooter() {
  return { type: FOOTER };
}

export function noFooter() {
  return { type: NO_FOOTER };
}

export function showHeaderFooter() {
  return { type: HEADER_FOOTER };
}

export function noHeaderFooter() {
  return { type: NO_HEADER_FOOTER };
}

export function showAll() {
  return { type: SHOW_ALL };
}

export function addNote(note) {
  return { type: ADD_NOTE, note: note };
}

export function removeNote(id) {
  return { type: REMOVE_NOTE, id: id };
}

export function toggleDocLeft(payload) {
  return { type: DOCUMENTS_LEFT, payload };
}

export function toggleProfLeft(payload) {
  return { type: PROFILE_LEFT, payload };
}