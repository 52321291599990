import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import DashboardLayout from './Layouts/Dashboard.Layout';
import AdminLayout from './Layouts/Admin.Layout';
import HomeLayout from './Layouts/Home.Layout';
import { Props } from './Redux/Models';
import { StateModel } from './Redux/Models/Base.Models';
import { ThemeProvider } from '@material-ui/core/styles';
import { PrimaryTheme } from './Theme';
import "./App.css";
import { USER_TYPES } from './Configs/Constants';

const App = (props: Props) => {
  return (
    <ThemeProvider theme={PrimaryTheme}>
      <Router>
        <Switch>
          {!props.isAuthenticated && <Route path="/" render={(rp) => <HomeLayout {...rp} {...props} />} />}
          {
            props.isAuthenticated &&
            <>
              {
                props.user.role === USER_TYPES.ADMIN ?
                  <Route path="/" render={(rp) => <AdminLayout {...rp} {...props} />} /> :
                  <Route path="/" render={(rp) => <DashboardLayout {...rp} {...props} />} />
              }
            </>
          }
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: StateModel) => {
  return {
    isAuthenticated: state.Auth.isAuthenticated,
    isExpired: state.Auth.isExpired,
    user: state.Auth.user
  };
};

export default connect(mapStateToProps)(App);


