export const USER_TYPES = {
    USER: 'USER',
    ADMIN: 'ADMINN'
}

export class Colors {
    public static black: string = '#040404';
    public static pink: string = '#feb6a0';
    public static orange: string = '#f26b41';
    public static orangeL: string = '#f3a95f';
    public static yellow: string = '#ffc924';
    public static blueD: string = '#29285d';
    public static blueL: string = '#c7ecff';
    public static green: string = '#65b89e';
    public static grey: string = '#807e7e;';
    public static greyM: string = '#e8e8e8';
    public static greyL: string = '#f9f9f9';
    public static greyL2: string = '#eeeeee';
    public static greyL3: string = '#f6f6f6';
    public static globalBoxShadow: string = '0px 10px 20px 0px rgba(0, 0, 0, 0.05)';

    public static big1Font: string = '65px';
    public static big2Font: string = '75px';
    public static mid1Font: string = '35px';
    public static mid2Font: string = '45px';
    public static sm1Font: string = '20px';
    public static sm2Font: string = '25px';
    public static sm3Font: string = '30px';

    public static big1FontB1: string = '40px';
    public static big2FontB1: string = '55px';
    public static mid1FontB1: string = '25px';
    public static mid2FontB1: string = '35px';
    public static sm1FontB1: string = '15px';
    public static sm2FontB1: string = '18px';
    public static sm3FontB1: string = '22px';

    public static big1FontB7: string = '30px';
    public static big2FontB7: string = '35px';
    public static mid1FontB7: string = '20px';
    public static mid2FontB7: string = '25px';
    public static sm1FontB7: string = '13px';
    public static sm2FontB7: string = '15px';
    public static sm3FontB7: string = '18px';



    //For 2k, 4k, 5k, 8k
    // For 2k
    public static big1Font2k: string = '95px';
    public static big2Font2k: string = '05px';
    public static mid1Font2k: string = '55px';
    public static mid2Font2k: string = '65px';
    public static sm1Font2k: string = '30px';
    public static sm2Font2k: string = '35px';
    public static sm3Font2k: string = '40px';

    // For 4k
    public static big1Font4k: string = '05px';
    public static big2Font4k: string = '25px';
    public static mid1Font4k: string = '70px';
    public static mid2Font4k: string = '80px';
    public static sm1Font4k: string = '40px';
    public static sm2Font4k: string = '45px';
    public static sm3Font4k: string = '50px';

    // For 6k
    public static big1Font6k: string = '25px';
    public static big2Font6k: string = '45px';
    public static mid1Font6k: string = '90px';
    public static mid2Font6k: string = '10px';
    public static sm1Font6k: string = '60px';
    public static sm2Font6k: string = '55px';
    public static sm3Font6k: string = '65px';

    //End of 2k, 4k, 6k, 8k

    // Break Points
    // For @media(max-width)
    public static b6k: string = '5760px';
    public static b4k: string = '3840px';
    public static b2k: string = '2560px';
    public static b1: string = '1659px';
    public static b2: string = '1439px';
    public static b3: string = '1199px';
    public static b4: string = '1023px';
    public static b5: string = '767px';
    public static b6: string = '600px';
    public static b7: string = '550px';
    public static b8: string = '400px';
    public static b9: string = '360px';
    // For @media(min-width)
    public static bu6k: string = '5761px';
    public static bu4k: string = '3841px';
    public static bu2k: string = '2561px';
    public static bu1: string = '1660px';
    public static bu2: string = '1440px';
    public static bu3: string = '1200px';
    public static bu4: string = '1024px';
    public static bu5: string = '768px';
    public static bu6: string = '601px';
    public static bu7: string = '551px';
    public static bu8: string = '401px';
    public static bu9: string = '361px';
    // For @media(max-height)
    public static bh1: string = '1079px';
    public static bh2: string = '899px';
    public static bh3: string = '767px';
    public static bh4: string = '599px';
    public static bh5: string = '499px';
}