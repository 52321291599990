import Configs from '../Configs';
export class Util {
    public static randomColor(): string {
        return '#' + Math.random().toString(10).substring(2, 8)
    }

    public static randomChar(): string {
        return (Math.random()).toString(36).substring(2, 8)
    }

    public static randomId(): string {
        return `${Math.random()}`.substring(2)
    }

    public static getBrokenName(fullName: string): Object {
        const parts = fullName.split(' ')
        const firstName = parts[0]
        const lastName = parts.length > 1 ? fullName.substring(fullName.indexOf(' ') + 1) : ''
        return { firstName, lastName }
    }

    public static getImageByPath(path: string): string {
        return `${Configs.API_ROOT}/${path}`
    }

    public static getFileName(name: string): string {
        return name.substring(0, name.lastIndexOf('.')) + '-signed-by-SignMate' + name.substring(name.lastIndexOf('.'))
    }

    public static generateSingleRecipient = () => ({
        name: null,
        email: null,
        id: this.randomId(),
        showAccessCode: false,
        color: this.randomColor(),
        accessCode: this.randomChar(),
    })
}