import loadable from "@loadable/component";
import { Fallback } from '../Components';
import { Routes } from "../Redux/Models";

const DashboardView = loadable(() => import("../Views/Dashboard/DashboardView"), { fallback: <Fallback /> });
const DocumentListView = loadable(() => import("../Views/Dashboard/DocumentListView"), { fallback: <Fallback /> });
const ProfileView = loadable(() => import("../Views/Dashboard/MyProfile"), { fallback: <Fallback /> });
const SignatureList = loadable(() => import("../Views/Dashboard/MyProfile/SignatureList"), { fallback: <Fallback /> });
const StampList = loadable(() => import("../Views/Dashboard/MyProfile/StampList"), { fallback: <Fallback /> });
const ChangePassword = loadable(() => import("../Views/Dashboard/MyProfile/ChangePassword"), { fallback: <Fallback /> });
const AddDocumentView = loadable(() => import("../Views/Dashboard/AddDocumentView"), { fallback: <Fallback /> });
const AddRecipientView = loadable(() => import("../Views/Dashboard/AddRecipientView"), { fallback: <Fallback /> });
const RecipientList = loadable(() => import("../Views/Dashboard/RecipientListView/RecipientList"), { fallback: <Fallback /> });
const SignerView = loadable(() => import("../Views/Dashboard/SignerView"), { fallback: <Fallback /> });

export const routes: Array<Routes> = [
    { path: '/', exact: true, name: 'Dashboard', component: DashboardView },
    { path: '/add-documents', exact: true, name: 'Add Document', component: AddDocumentView },
    { path: '/profile', exact: false, name: 'Profile', component: ProfileView },
    { path: '/change-password', exact: true, name: 'Change Password', component: ChangePassword },
    { path: '/signatures', exact: true, name: 'Signatures', component: SignatureList },
    { path: '/stamps', exact: true, name: 'Stamps', component: StampList },
    { path: '/documents/:documentId', exact: true, name: 'Document Signer', component: SignerView },
    { path: '/documents/:documentId/recipients', exact: true, name: 'Add Recipients', component: AddRecipientView },
    { path: '/recipients', exact: true, name: 'Recipients List', component: RecipientList },
    { path: '/documents', exact: true, name: 'Documents', component: DocumentListView },
];
