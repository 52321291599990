import styled from 'styled-components';
// import Menu from '@material-ui/core/Menu';

// export const MenuPart = styled.div(Menu)`
//     .MuiMenu-paper{
//         padding: 0 20px;
//         border: 1px solid black;
//         top: 67px;
//         right: 94px;
//         border-radius: 10px;
//     }
// `;

export const ProfileLeftSection = styled.section`
    // Common Font Starts
    .m-font {
        font-size: 20px;
    }
    // Common Font Ends
    /* padding: 80px 0 0; */
    margin-top: 80px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 400px;
    overflow: auto;
    // display: none;
    box-shadow: 0 3px 31px 0 rgba(0, 0, 0, 0.11);
    background-color: #fff;
    z-index: 1;
    .upload-photo {
        width: 100%;
        text-align: center;
        .display-picture-section {
            cursor: pointer;
            display: block;
            position: relative;
            width: 250px;
            height: 250px;
            margin: 20px auto 20px;
            border-radius: 50%;
            .MuiAvatar-root {
                width: 100%;
                height: 100%;
                font-size: 100px;
            }
            .initials {
                color: #f1781d;
                background-color: #f1781d57;
                border: 3px solid #f1781d;
            }
            input {
                height: 0;
                width: 0;
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: -9999;
            }
            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                transform: scale(0);
                transition: 0.5s;
                border-radius: 50%;
                div {
                    width: 100%;
                    text-align: center;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    color: #fff;
                    font-size: 20px;
                    img {
                        width: 20%;
                    }
                }
            }
            &:hover .overlay {
                transform: scale(1);
            }
        }
        .submit-btn {
            border: 2px solid #000;
            img,
            span {
                vertical-align: middle;
            }
            img {
                width: 30px;
                margin: 0 5px 0 0;
            }
        }
    }
    ul {
        list-style-type: none;
        padding: 0;
        padding: 23.5%;
        li {
            margin: 0 0 13.19%;
            a {
                color: #5f5a5a;
                &:hover {
                    text-decoration: none;
                    color: #000;
                    font-weight: 900;
                }
            }
            &.active {
                a {
                    color: #000;
                    font-weight: 900;
                }
            }
        }
    }
    .MuiButton-endIcon img {
        width: 25px;
    }
    @media only screen and (max-width: 1800px) {
        width: 350px;
        .m-font {
            font-size: 19px;
        }
        .upload-photo .display-picture-section {
            width: 240px;
            height: 240px;
            .MuiAvatar-root {
                font-size: 95px;
            }
        }
    }

    @media only screen and (max-width: 1600px) {
        width: 300px;
        .m-font {
            font-size: 18px;
        }
        ul.m-font {
            padding: 30px;
        }
        .upload-photo .display-picture-section {
            width: 230px;
            height: 230px;
            .MuiAvatar-root {
                font-size: 90px;
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        width: 250px;
        .m-font {
            font-size: 16px;
        }
        .upload-photo .display-picture-section {
            width: 210px;
            height: 210px;
            .MuiAvatar-root {
                font-size: 80px;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
    }

    @media only screen and (max-width: 900px) {
        padding-top: 0;
        // width: 35%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        transform: translateX(-100%);
        transition: 500ms;
        &.leftbar-open {
            transform: translateX(0%);
        }
        ul.m-font {
            font-size: 16px;
            padding: 0px 10px;
        }
    }

    @media only screen and (max-width: 600px) {
        // width: 75%;
    }
`;

export const ProfileRightSection = styled.section`
    padding: 80px 0 0;
    position: fixed;
    top: 0;
    left: 22.4%;
    height: 100%;
    overflow: auto;
    width: calc(100% - 22.4%);
    background-color: #f7f8fc;
    .inner-wrapper {
        padding: 4.03% 10.07% 4.03% 4.7%;
        .custom-tab {
            padding: 4.75% 6.33% 7.11% 6.33%;
            border-radius: 10px;
            background: #fff;
        }
    }
    @media only screen and (max-width: 1800px) {
    }

    @media only screen and (max-width: 1600px) {
    }

    @media only screen and (max-width: 1400px) {
    }

    @media only screen and (max-width: 1200px) {
    }

    @media only screen and (max-width: 900px) {
    }

    @media only screen and (max-width: 600px) {
    }
`;

export const AvatarDropDown = styled.div`
    .MuiAvatar-root.avatarTrigger {
        width: 60px;
        height: 60px;
        color: #f1781d;
        background-color: #f1781d57;
        font-size: 35px;
        cursor: pointer;
    }

    @media only screen and (max-width: 1800px) {}

    @media only screen and (max-width: 1600px) {
        .MuiAvatar-root.avatarTrigger {
            width: 50px;
            height: 50px;
            font-size: 30px;
        }
    }

    @media only screen and (max-width: 1400px) {
        .MuiAvatar-root.avatarTrigger {
            width: 40px;
            height: 40px;
            font-size: 25px;
        }
    }

    @media only screen and (max-width: 1200px) {}

    @media only screen and (max-width: 900px) {
        .MuiAvatar-root.avatarTrigger {
            width: 30px;
            height: 30px;
            font-size: 20px;
        }
    }

    @media only screen and (max-width: 600px) {}
`

export const SectionHeaderWrapper = styled.h3`
    font-size: 30px;
    font-weight: 700;
    @media only screen and (max-width: 1800px) {
        font-size: 27px;
    }
    @media only screen and (max-width: 1600px) {
        font-size: 24px;
    }
    @media only screen and (max-width: 1400px) {
        font-size: 21px;
    }
    @media only screen and (max-width: 1023px) {
        font-size: 19px;
    }
`;