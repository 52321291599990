import React from 'react';
// import "./styles.css"
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Logout } from '../../Redux/Actions';
import { Avatar, Divider } from '@material-ui/core';
import { Util } from '../../Helpers';
import { AvatarDropDown } from './styled';

function HeaderDropDown({ user, dispatch }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        dispatch(Logout());
        handleClose();
    };

    return (
        <AvatarDropDown className="">
            <Avatar className="avatarTrigger" alt={`${user.firstName} ${user.lastName}`.toUpperCase()} src={Util.getImageByPath(user.profileImg)} onClick={handleClick} />
            <Menu className="avatarMenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} >
                <MenuItem style={{ backgroundColor: 'white' }} onClick={handleClose}>
                    <Link style={{ color: 'black' }} to="/documents?type=inbox"> Dashboard </Link>
                </MenuItem>
                <Divider />
                {/* <MenuItem style={{ backgroundColor: 'white' }} onClick={handleClose}>
                    <Link style={{ color: 'black' }} to="/recipients"> {'Recipients'} </Link>
                </MenuItem> */}
                <Divider />
                <MenuItem style={{ backgroundColor: 'white' }} onClick={handleClose}>
                    <Link style={{ color: 'black' }} to="/profile"> Profile </Link>
                </MenuItem>
                <Divider />
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </AvatarDropDown>
    );
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.Auth.isAuthenticated,
        user: state.Auth.user
    };
};

export default connect(mapStateToProps)(HeaderDropDown);
