// import { GetValue } from '../../Helpers/Object'
import { BaseModel } from "./Base.Models";

export class UserModel extends BaseModel {
    public constructor(params = {}){
        super();
        Object.assign(this, params);
    }
    public firstName?: string;
    public lastName?: string;
    public jobTitle ?: string;
    public phoneNumber ?: string;
    public email ?: string;
    public country ?: string;
    public companyName ?:string;
    public companySize ?:string;
    public industry ?:string;
    public trailReason ?:string;
    public needCRM ?:boolean;
    public owner?: string;
    public addedBy?: string;
    public password ?: string;
    public isBlocked?: boolean;
    public token?:string;
    public confirmPassword ?:string;
    public verification_code?: string;

}

export class AuthUserModel extends UserModel {
    public constructor(params = {}){
        super(params)
        Object.assign(this, params);
    }
}

export class LoadUserModel {
    public token: string;
    public user: UserModel
}