import { PROFILE_LEFT, DOCUMENTS_LEFT  } from "../Actions/action";

const initialState: any = {
   isDocListLeftBarOpen: false,
   isProfileLeftBarOpen: false
}

const LeftBar = (state = initialState, action: any): any => {
    switch (action.type) {
        case PROFILE_LEFT: {   
            return {
                ...state,
                isProfileLeftBarOpen: action.payload
            }
        }
        case DOCUMENTS_LEFT: {
            return {
                ...state,
                isDocListLeftBarOpen: action.payload
            }
        }
        default: {
            return state;
        }
    }
}

export default LeftBar;