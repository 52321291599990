import { Subject, fromEvent, BehaviorSubject } from "rxjs";
const windowScrollEvent = new Subject();
const windowResizeEvent = new Subject();
const signatureDataTransfer = new Subject();
const selectPageDataTransfer = new Subject();
const scaleResize = new BehaviorSubject(null);

const source = fromEvent(window, 'scroll');
source.subscribe(val => windowScrollEvent.next(val.target.defaultView.pageYOffset));

const sourceRsize = fromEvent(window, 'resize');
sourceRsize.subscribe(() => windowResizeEvent.next(window.innerWidth));

export { windowScrollEvent, windowResizeEvent, signatureDataTransfer, selectPageDataTransfer, scaleResize };
